import React from 'react';
import logo from './logo1.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo"/>
        <p>
          • Педиатр 24/7
          • ЛОР 24/7
          • Эндокринолог
          • Терапевт 24/7
          • Невролог
          • Уролог
          • Гинеколог
          • Узд
          • Стоматология 24/7
        </p>
        <p>
          <a href="tel:889251111">Aloqa uchun</a>
        </p>
          <a href="https://yandex.uz/navi/-/CDroB6mr">Lacatsiya</a>
<br/>
        <a
            className="App-link"
            href="https://telegram.me/rustam712"
            target="_blank"
            rel="noopener noreferrer"
        >
          Tezkunda ....
        </a>
      </header>
    </div>
  );
}

export default App;
